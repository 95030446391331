import { Reservation } from "@/interfaces/reservation";
import { EventBus } from "@/internal";
import { SecurityPinService } from "@/plugins/security-pin/security-pin.service";
import { reservationsService } from "@/services/reservations.service";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./ReleaseReservationModal.template.vue";
@Component({
  mixins: [Template]
})
export default class ReleaseReservationModalComponent extends Vue {
  public loading = false;
  @Prop({ required: true })
  public reservation!: Reservation;
  public retailSaveType = [
    "RETAIL_SALE",
    "RETAIL_ORDER",
    "SAVED_ORDER",
    "PENDING_ORDER"
  ];
  public oofTypes = [
    "IN_STORE_PENDING_PREORDER",
    "CALL_IN_PENDING_PREORDER",
    "ONLINE_PENDING_PREORDER",
    "IN_STORE_FILLED_PREORDER",
    "CALL_IN_FILLED_PREORDER",
    "ONLINE_FILLED_PREORDER"
  ];
  public release(to: string) {
    if (to === "OOF") {
      this.$router.push({
        path: "/retail/order-fulfillment",
        query: { type: this.reservation.operationType }
      });
      return;
    }
    this.loading = true;
    const pin$ = new SecurityPinService();
    return pin$
      .ensure("reservations.release_reservation", {
        text: "reservations.enter_pin"
      })
      .then(
        pin => {
          reservationsService
            .release(this.reservation.operation.operation_uid, pin)
            .then(response => {
              if (response) {
                this.$emit("resolve");
              }
              this.loading = false;
            });
        },
        () => {
          EventBus.$emit("notify", {
            icon: "fas fa-exclamation-circle",
            text: "required_pin",
            color: "error"
          });
          this.loading = false;
          return "";
        }
      );
  }

  public imgError() {
    this.reservation.user.avatar_thumb_url = "";
  }
}
