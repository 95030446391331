var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"release py-4 px-1"},[_c('v-layout',{staticClass:"release__title text-xs-center"},[_c('v-flex',[_vm._v(_vm._s(_vm.$t("reservations.release_reservation")))])],1),_vm._v(" "),_c('v-layout',{staticClass:"release__message text-xs-center py-4"},[(
        _vm.retailSaveType.indexOf(_vm.reservation.operation.operation_type_name) > -1
      )?_c('v-flex',[_c('div',[_vm._v(_vm._s(_vm.$t("reservations.action_message_release_1")))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.$t("reservations.action_message_release_2")))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.$t("reservations.action_message_release_3")))])]):(
        _vm.oofTypes.indexOf(_vm.reservation.operation.operation_type_name) > -1
      )?_c('v-flex',[_c('div',[_vm._v(_vm._s(_vm.$t("reservations.oof_message_line_1")))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.$t("reservations.oof_message_line_2")))])]):_c('v-flex',[_c('div',[_vm._v(_vm._s(_vm.$t("reservations.message_release_1")))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.$t("reservations.message_release_2")))])])],1),_vm._v(" "),_c('v-layout',{staticClass:"release__info px-3 py-1"},[(_vm.reservation.user)?_c('v-layout',{attrs:{"row":"","align-center":""}},[_c('v-flex',{attrs:{"sm-6":"","mr-2":""}},[_c('v-avatar',{attrs:{"size":"40"}},[(_vm.reservation.user.avatar_thumb_url)?_c('v-img',{staticClass:"release__info__img",attrs:{"src":_vm.reservation.user.avatar_thumb_url},on:{"error":function($event){_vm.imgError(_vm.reservation)}}}):_c('span',{staticClass:"subheading text-uppercase release__info__initials"},[_vm._v(_vm._s(("" + (_vm.reservation.user.first_name.substring(
                0,
                1
              )) + (_vm.reservation.user.last_name.substring(0, 1)))))])],1)],1),_vm._v(" "),_c('v-flex',{staticClass:"px-1",attrs:{"sm-4":""}},[_c('span',{staticClass:"release__info__name"},[_vm._v(_vm._s(((_vm.reservation.user.first_name) + " " + (_vm.reservation.user.last_name))))])])],1):_c('v-layout',{attrs:{"row":"","align-center":""}},[_c('v-flex',{staticClass:"px-1",attrs:{"sm-4":""}},[_c('span',{staticClass:"release__info__name"},[_vm._v("--")])])],1),_vm._v(" "),_c('v-flex',{staticClass:"px-1 text-xs-center"},[_vm._v(_vm._s(_vm.$t(
        ("reservations." + (_vm.reservation.operation.operation_type_name.toLowerCase()))
      )))]),_vm._v(" "),_c('v-flex',{staticClass:"px-1"},[_vm._v(_vm._s(_vm._f("fnsCompleteDate")(_vm.reservation.operation.created_at)))])],1),_vm._v(" "),(
      _vm.retailSaveType.indexOf(_vm.reservation.operation.operation_type_name) > -1
    )?_c('v-layout',{staticClass:"pt-4",attrs:{"justify-center":"","row":""}},[_c('v-flex',{attrs:{"sm3":""}},[_c('v-btn',{staticClass:"release__btn button",attrs:{"loading":_vm.loading,"disabled":_vm.loading,"small":""},on:{"click":function($event){_vm.release('RESERVATION')}}},[_vm._v(_vm._s(_vm.$t("yes")))])],1),_vm._v(" "),_c('v-flex',{attrs:{"sm3":""}},[_c('v-btn',{staticClass:"release__btn button",attrs:{"loading":_vm.loading,"disabled":_vm.loading,"small":""},on:{"click":function($event){_vm.$emit('reject')}}},[_vm._v(_vm._s(_vm.$t("no")))])],1)],1):(
      _vm.oofTypes.indexOf(_vm.reservation.operation.operation_type_name) > -1
    )?_c('v-layout',{staticClass:"pt-4",attrs:{"justify-center":"","row":""}},[_c('v-flex',{attrs:{"sm3":""}},[_c('v-btn',{staticClass:"release__btn button",attrs:{"loading":_vm.loading,"disabled":_vm.loading,"small":""},on:{"click":function($event){_vm.release('OOF')}}},[_vm._v(_vm._s(_vm.$t("reservations.to_oof")))])],1)],1):_c('v-layout',{staticClass:"pt-4",attrs:{"justify-center":"","row":""}},[_c('v-flex',{attrs:{"sm3":""}},[_c('v-btn',{staticClass:"release__btn button",attrs:{"loading":_vm.loading,"disabled":_vm.loading,"small":""},on:{"click":function($event){_vm.release()}}},[_vm._v(_vm._s(_vm.$t("reservations.release_reservation")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }