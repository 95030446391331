import { Reservation } from "@/interfaces/reservation";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./ReservationAuditModal.template.vue";
@Component({
  mixins: [Template]
})
export default class ReservationAuditModalComponent extends Vue {
  public loading = false;
  @Prop({ required: true })
  public reservation!: Reservation;

  public goToAudit() {
    this.$router.push({
      name: "audit-edit",
      params: {
        operationId: this.reservation.auditId || ""
      }
    });
  }

  public imgError() {
    this.reservation.user.avatar_thumb_url = "";
  }
}
