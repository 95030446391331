import {
  FilterModel,
  OperationsType,
  Reservation,
  ReservationItems
} from "@/interfaces/reservation";
import { messagesService } from "@/services/messages.service";
import { TablePagination } from "helix-vue-components";
import Vue from "vue";
import HttpService from "./http.service";

class ReservationsService extends HttpService {
  protected uri: string = "/inventory/reservations";

  public async getReservations(
    pagination: TablePagination | null,
    filter: FilterModel | null
  ): Promise<Reservation[]> {
    try {
      this.uri = "/inventory/reservations";
      const query = pagination
        ? {
            page: pagination.currentPage,
            per_page: pagination.itemsPerPage,
            "q[operation.operation_type_id_is_in]":
              (filter && filter.actions) || null,
            "q[user_id_is_in]": (filter && filter.users) || null,
            "q[operation.created_at_eq]":
              (filter && filter.startDate && (filter.startDate as string)) ||
              null
          }
        : { ...this.query };
      const response = await super.get({
        ...query
      });
      return response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }

  public async getReservationItems(
    reservationId: number
  ): Promise<ReservationItems[]> {
    const uri = this.uri;
    this.uri = `inventory/reservations/${reservationId}/items`;
    try {
      const response = await super.get({});
      this.uri = uri;
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    } finally {
      this.uri = uri;
    }
  }

  public async getOperationsType(): Promise<OperationsType[]> {
    try {
      const uri = this.uri;
      this.uri = "inventory/operation_types";
      const response = await super.get({ no_pagination: true });
      this.uri = uri;
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }

  public async release(id: string, pincode: string): Promise<boolean> {
    try {
      await Vue.axios({
        method: "PATCH",
        url: `inventory/operations/${id}/abort`,
        headers: {
          Pincode: `${pincode}`
        }
      });
      messagesService.renderSuccessMessage("reservations.release_success");
      return true;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return false;
    }
  }
}

export const reservationsService: ReservationsService = new ReservationsService();
