import { Operations, OperationsType } from "@/interfaces/reservation";
import { User } from "@/interfaces/user";
import { i18n } from "@/plugins/i18n";
import {
  CallbackPromise,
  FormFieldTypes,
  HelixDatePickerTypes,
  TableFieldType,
  TableHeader
} from "helix-vue-components";

export const filterFormConfig = (
  actionsCallback: CallbackPromise<OperationsType[]>,
  usersCallback: (d: { newValue: string }) => Promise<User[] | undefined>
) => [
  {
    component: FormFieldTypes.datepicker,
    label: i18n.t("reservations.start_date"),
    name: "startDate",
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        format: "MM/dd/yyyy hh:mm a"
      },
      placeholder: i18n.t("reservations.start_date"),
      row: 1,
      flex: 4
    }
  },
  {
    component: FormFieldTypes.AutocompleteComponent,
    label: i18n.t("reservations.actions"),
    name: "actions",
    options: {
      placeholder: i18n.t("type_to_search"),
      row: 1,
      flex: 4,
      associationValue: "id",
      associationText: "name",
      selectOptions: () =>
        actionsCallback().then(data =>
          data.map(action => {
            return {
              id: action.id + "_" + action.type,
              name: i18n
                .t(`reservations.${action.name.toLowerCase()}`)
                .toString()
            };
          })
        ),
      vuetifyProps: {
        "item-value": "id",
        "deletable-chips": true,
        "cache-items": true,
        multiple: true,
        chips: true
      }
    }
  },
  {
    component: FormFieldTypes.asyncAutocomplete,
    label: i18n.t("reservations.user"),
    name: "users",
    options: {
      placeholder: i18n.t("type_to_search"),
      row: 1,
      flex: 4,
      associationValue: "id",
      associationText: "name",
      selectOptions: usersCallback,
      vuetifyProps: {
        "no-data-text": i18n.t("no_search_results"),
        "item-value": "id",
        "deletable-chips": true,
        "cache-items": true,
        multiple: true,
        chips: true
      }
    }
  }
];
export const ReservationsTableMetadata: TableHeader[] = [
  {
    value: "avatar_medium_url",
    label: "",
    fieldComponent: TableFieldType.custom,
    size: 1
  },
  {
    value: "user_name",
    label: String(i18n.t("reservations.user")),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong"
  },
  {
    value: "operation.operation_type_name",
    label: String(i18n.t("reservations.action")),
    fieldComponent: TableFieldType.enumText,
    enum: Operations
  },
  {
    value: "operation.created_at",
    label: String(i18n.t("reservations.start_date")),
    fieldComponent: TableFieldType.fnsDate,
    class: "tdt__headers__fieldLong",
    sortable: false
  }
];
