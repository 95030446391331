import { policyList } from "@/enums/permissions";
import {
  FilterModel,
  OperationsType,
  Reservation,
  ReservationItems,
  ReservationItemsFormatted
} from "@/interfaces/reservation";
import { RetailSettings } from "@/interfaces/retailSettings";
import { User } from "@/interfaces/user";
import {
  filterFormConfig,
  ReservationsTableMetadata
} from "@/metadata/reservations";
import { auditService } from "@/services/audit.service";
import { reservationsService } from "@/services/reservations.service";
import { usersService } from "@/services/user.service";
import { PageNavAction } from "@/types/types";
import { sumArray } from "@/utils/math.utils";
import {
  BooleanCheck,
  DynamicFormComponent,
  FormField,
  TableAction,
  TableComponent,
  TablePagination
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import ReleaseReservationModalComponent from "./ReleaseReservationModal/ReleaseReservationModal.component";
import ReservationAuditModalComponent from "./ReservationAuditModal/ReservationAuditModal.component";
import Template from "./Reservations.template.vue";

@Component({
  mixins: [Template],
  components: {
    ReleaseReservationModalComponent,
    ReservationAuditModalComponent,
    DynamicFormComponent,
    TableComponent
  }
})
export default class ReservationComponent extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;

  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;

  public loading = false;
  public pagination: TablePagination | null = null;
  public reservations: Reservation[] = [];
  public users: User[] = [];
  public headers = ReservationsTableMetadata;
  public fieldsConfig: FormField[] | null = null;
  public operations: OperationsType[] = [];
  public loadingExpand = false;
  public loadingModal = false;
  public showExpand = false;
  public currentItems: ReservationItems[] = [];
  public filterModel: FilterModel | null = {
    startDate: "",
    actions: [],
    users: []
  };

  public isAudit(data: Reservation) {
    return data.operationType === "AUDIT";
  }

  public isCustomer(data: Reservation) {
    const isAvailable = [
      "RETAIL_SALE",
      "SAVED_ORDER",
      "PENDING_ORDER",
      "IN_STORE_PENDING_PREORDER",
      "CALL_IN_PENDING_PREORDER",
      "ONLINE_PENDING_PREORDER",
      "IN_STORE_FILLED_PREORDER",
      "CALL_IN_FILLED_PREORDER",
      "ONLINE_FILLED_PREORDER"
    ];
    return isAvailable.includes(data.operationType);
  }

  public async setDataAudit(index: number) {
    const currentReservation = this.reservations[index];
    this.reservations[index].auditId = await auditService.getAuditsId(
      currentReservation.operation.operation_uid
    );
    this.reservations[index].rooms = this.reservations[index].locations
      .map(r => r.name)
      .join(", ");
  }

  public async expand(data: { index: number; isOpening: boolean }) {
    this.loadingExpand = true;
    if (
      this.isAudit(this.reservations[data.index]) &&
      (!this.reservations[data.index].auditId ||
        !this.reservations[data.index].rooms)
    ) {
      await this.setDataAudit(data.index);
    }
    if (
      !this.isAudit(this.reservations[data.index]) &&
      data.isOpening &&
      !this.reservations[data.index].reservation_items
    ) {
      this.currentItems = await reservationsService.getReservationItems(
        this.reservations[data.index].id
      );
      this.reservations[data.index].reservation_items = cloneDeep(
        this.currentItems
      );
    }
    this.loadingExpand = false;
  }

  public async releaseReservation(reservation: Reservation, index: number) {
    this.loadingModal = true;
    if (this.isAudit(reservation) && !reservation.auditId) {
      await this.setDataAudit(index);
    }
    this.loadingModal = false;
    try {
      await this.$modals.load(
        this.isAudit(reservation)
          ? ReservationAuditModalComponent
          : ReleaseReservationModalComponent,
        {
          size: "fit",
          positionY: "center"
        },
        {
          reservation
        }
      );
      this.triggerFilter();
    } catch (e) {
      // avoid uncaught rejection.
    }
  }

  public getRowActions(): TableAction[] {
    return [
      {
        icon: "fal fa-unlink",
        action: this.releaseReservation,
        tooltip: this.$t("reservations.release_reservation").toString(),
        visibleCondition: (reservation: { item: Reservation }) =>
          this.hasPermission(policyList.releaseReservations)
      }
    ];
  }

  public formatbatchList(data: Reservation): ReservationItemsFormatted[] {
    return map(
      groupBy(data.reservation_items, ri =>
        ri.batch_fraction_uid.substring(0, 16)
      ),
      (d, key) => {
        return {
          batch_id: key,
          quantity: sumArray(d, "quantity_value"),
          quantity_unit: d[0].quantity_unit || "",
          product_name: d[0].product.name,
          biotrack_trace_id: d[0].biotrack_traceability_id,
          rooms: d.map(r => {
            return {
              name:
                data.locations.find(l => l.id === r.inventory_location_id)!
                  .name || "",
              quantity: `${r.quantity} `
            };
          })
        };
      }
    );
  }

  public userList = async (scope: { newValue: string }) => {
    if (!scope.newValue || scope.newValue.length > 2) {
      const response = await usersService.searchUsers(scope.newValue);
      this.users = response;
      return this.users;
    }
  };

  public async getOperations() {
    return await reservationsService.getOperationsType();
  }

  public async created() {
    this.setPageNav({
      title: "reservations.reservations",
      secondaryActions: {
        component: DynamicFormComponent,
        props: {
          class: "pb-4",
          fieldsConfig: filterFormConfig(this.getOperations, this.userList),
          model: this.filterModel,
          ref: "filter-form"
        },
        events: [
          {
            event: "change",
            callback: this.onChangeFilter,
            ref: "filter-form"
          }
        ]
      }
    });
    await this.triggerFilter();
  }
  /**
   * Updates requests on table page change event.
   * @param pagination: TablePagination
   */
  public onChangePage(pagination: TablePagination) {
    this.pagination = { ...pagination };
    this.triggerFilter();
  }

  private async triggerFilter() {
    this.loading = true;
    const reservations = await reservationsService.getReservations(
      this.pagination,
      this.filterModel
    );
    this.reservations =
      (reservations &&
        reservations.length &&
        reservations.map(r => {
          const firstName = r.user
            ? r.user.first_name
              ? r.user.first_name
              : "-"
            : "-";
          const lastName = r.user
            ? r.user.last_name
              ? r.user.last_name
              : "-"
            : "-";
          return {
            ...r,
            avatar_medium_url: r.user
              ? r.user.avatar_medium_url
                ? r.user!.avatar_medium_url
                : ""
              : "",
            operationType: r.operation.operation_type_name,
            user_name: `${firstName} ${lastName}`
          };
        })) ||
      [];
    this.pagination = cloneDeep(await reservationsService.getPagination());
    this.loading = false;
  }

  private onChangeFilter(filter?: DynamicFormComponent) {
    this.filterModel = (filter && (filter.currentModel as FilterModel)) || null;
    this.triggerFilter();
  }
}
