export interface Reservation {
  reservation_items: ReservationItems[];
  locations: InventoryLocation[];
  user: ReservationUser;
  id: number;
  user_id: number;
  user_name?: string;
  avatar_medium_url?: string;
  operation: {
    operation_uid: string;
    operation_type_name: string;
  };
  operationType: string;
  rooms?: string;
  auditId?: string;
}

export interface FilterModel {
  startDate: string;
  actions: number[];
  users: number[];
}

export interface ReservationItems {
  batch_fraction_uid: string;
  inventory_location_id?: number;
  quantity: string;
  quantity_unit: string;
  product: { name: string };
  biotrack_traceability_id: string;
}

export interface ReservationItemsFormatted {
  batch_id: string;
  rooms: Array<{
    name: string;
    quantity: string;
  }>;
  quantity: number;
  quantity_unit: string;
}

export interface InventoryLocation {
  name: string;
  id: number;
}

export interface ReservationUser {
  avatar_original_url: string;
  avatar_thumb_url: string;
  avatar_medium_url?: string;
  first_name: string;
  last_name: string;
}

export interface OperationsType {
  id: number;
  name: string;
  type: string;
}

export enum Operations {
  COMBINE = "reservations.combine",
  CONVERT = "reservations.convert",
  MOVE = "reservations.move",
  SPLIT = "reservations.split",
  RETAIL_SALE = "reservations.retail_sale",
  CREATE_BATCH = "reservations.create_batch",
  ADJUST = "reservations.adjust",
  AUDIT = "reservations.audit",
  SAVED_ORDER = "reservations.saved_order",
  PENDING_ORDER = "reservations.pending_order",
  ONLINE_PENDING_PREORDER = "reservations.online_pending_preorder",
  ONLINE_FILLED_PREORDER = "reservations.online_filled_preorder",
  CALL_IN_PENDING_PREORDER = "reservations.call_in_pending_preorder",
  IN_STORE_PENDING_PREORDER = "reservations.in_store_pending_preorder",
  CALL_IN_FILLED_PREORDER = "reservations.call_in_filled_preorder",
  IN_STORE_FILLED_PREORDER = "reservations.in_store_filled_preorder",
  OUTGOING_BATCH_TRANSFER = "reservations.outgoing_batch_transfer",
  RETAIL_SALE_VOID = "reservations.retail_sale_void",
  TRANSFER_BATCH_OWNERSHIP = "reservations.transfer_batch_ownership",
  BATCH_MODIFICATION = "reservations.batch_modification",
  TRANSFER_MODIFICATION = "reservations.transfer_modification",
  TRANSFER_VOID = "reservations.transfer_void",
  INVENTORY_RECONCILIATION = "reservations.inventory_reconciliation",
  CHANGE_SKU = "reservations.change_sku",
  RETAIL_SALE_REFUND = "reservations.retail_sale_refund",
  VOID_TRANSFER_BATCH = "reservations.void_transfer_batch",
  BATCH_SPLIT_FROM_REFUND = "reservations.batch_split_from_refund",
  CREATED_FROM_BATCH_SPLIT_REFUND = "reservations.created_from_batch_split_refund"
}
